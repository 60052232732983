import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import { graphql } from "gatsby";
export const query = graphql`
query ($myComponentPath: String!) {
    mdx(fileAbsolutePath: {eq: $myComponentPath}) {
        frontmatter {
            image {
                publicURL
            }
            imageSmall {
                publicURL
            }
        }
    }
}
`;
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = null;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" components={components}>
      <MDXTag name="p" components={components}>{`This article explains what an npm `}<MDXTag name="inlineCode" components={components} parentName="p">{`co`}</MDXTag>{` module is, how it works and how it can help you to write much cleaner and simpler asynchronous code.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`The problem`}</MDXTag>
      <MDXTag name="p" components={components}>{`Writing asynchronous code could be very tricky, if you never did it before or didn't learn or develop good patterns to approach it. Asynchronous programming in javascript became popular with arrival of single page applications and node.js (where most of the operations happen asynchronously by default). Traditionally javascript was handling async operations using callbacks, and at least once every web developer faced a problem called `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.google.ca/search?q=callback+hell+definition&tbm=isch"
        }}><MDXTag name="inlineCode" components={components} parentName="a">{`callback hell`}</MDXTag></MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="p">{`pyramid of doom`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Solutions`}</MDXTag>
      <MDXTag name="p" components={components}>{`A simple solution is to keep your code shallow, though error handling wouldn't be that simple. Another one, very well established solution is to use promises. Using ES6 generators we can simplify code for promises and continue writing code in a synchronous, easy to follow, manner, while keeping it asynchronous. If you're not familiar with how `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "#promises"
        }}>{`promises`}</MDXTag>{` or `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "#generators"
        }}>{`generators`}</MDXTag>{` are working, please do a quick research before you continue reading.`}</MDXTag>
      <MDXTag name="h3" components={components}>{`co to the rescue`}</MDXTag>
      <MDXTag name="p" components={components}>{`co is a wrapper around promises and generators which allows to simplify asynchronous code a lot. Take a look at the example which shows how an async code could be written in a sync manner using co:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`co(function* getResults(){
  //assume read and request perform asynchronous actions
  var a = read('index.js', 'utf8');
  var b = request('http://google.ca');

  //when all operations will finish
  //res will have contents of index.js at [0]
  //and response body from google at [1]
  var res = yield [a, b];
  //...code which does something with res
}).catch(function (ex) {
  //if an error was thrown from read or request functions
});
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Here is what co will do for us:`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`Both read and request will execute in parallel on line 7 and when both of them finish - res array will hold results. It doesn't matter if request finishes before read - co will ensure that results are assigned to appropriate indexes.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`On node.js you would have to check for errors in both callbacks for read and request functions, to ensure that your application does not exit in an unhandled state, but with co we can only worry about an end result and add a single error handler using `}<MDXTag name="inlineCode" components={components} parentName="li">{`.catch`}</MDXTag>{`.`}</MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`Code is rather short and easy to understand`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`So what exactly is happening? How does it work? To understand it better lets go over code of the main function in co and describe it. `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/tj/co/blob/48ca4dcfa6b6a7396aef57e8d78928cfffc814bc/index.js#L41"
        }}>{`Open`}</MDXTag>{` code in new window so that you can put it side by side.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Let\`s modify example just a little, in order to show how various scenarios will work in co:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//example.js
co(function* getResults(){
  //not really doing anything important,
  //but will be useful to show how co works
  try {
    yield false;
  } catch (ex) {
    console.log(ex.message);
  }

  //assume read and request perform asynchronous actions
  var a = read('index.js', 'utf8');
  var b = request('http://google.ca');

  //when all operations will finish
  //res will have contents of index.js at [0]
  //and response body from google at [1]
  var res = yield [a, b];
  //...code, which does something with res
}).catch(function (ex) {
  //if an error was thrown from read or request functions
});
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`First co saves context reference, in case you will ever want to use a context inside read or request functions:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:co
var ctx = this;

//Context binding example
co.call(context, function* getResults() {/*'this' will point to context*/})
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Then it checks, if `}<MDXTag name="inlineCode" components={components} parentName="p">{`gen`}</MDXTag>{` is a function. If it is, then co needs to initialize a generator object by calling a function and it also ensure that `}<MDXTag name="inlineCode" components={components} parentName="p">{`this`}</MDXTag>{` has a proper context. If `}<MDXTag name="inlineCode" components={components} parentName="p">{`gen`}</MDXTag>{` is already a generator object, a statement will be skipped:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:co
if (typeof gen === 'function') gen = gen.call(this);
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Then co returns a new Promise and runs `}<MDXTag name="inlineCode" components={components} parentName="p">{`onFulfilled`}</MDXTag>{` function with no arguments. Inside that function it will try to execute a generator code until a first `}<MDXTag name="inlineCode" components={components} parentName="p">{`yield`}</MDXTag>{` statement.`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:onFulfilled
var ret;
try {
  ret = gen.next(res); //res is undefined
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`In our case following code would execute inside `}<MDXTag name="inlineCode" components={components} parentName="p">{`getResults`}</MDXTag>{` generator:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//example.js
try {
  yield false;
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If that code could throw an error - promise would be rejected with an error object and co would exit.`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:onFulfilled
catch (e) {
  return reject(e);
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`In our case generator returns an object with a `}<MDXTag name="inlineCode" components={components} parentName="p">{`value`}</MDXTag>{` property containing yielded value and this object is assigned to `}<MDXTag name="inlineCode" components={components} parentName="p">{`ret`}</MDXTag>{`. At that moment of time code would look like that:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:onFulfilled
var ret;
try {
  ret = {value: false, done: false};
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Then `}<MDXTag name="inlineCode" components={components} parentName="p">{`onFulfilled`}</MDXTag>{` will call `}<MDXTag name="inlineCode" components={components} parentName="p">{`next(ret);`}</MDXTag>{`. If `}<MDXTag name="inlineCode" components={components} parentName="p">{`done`}</MDXTag>{` would be true, co would resolve a promise with the value and exit from next:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js",
          "metastring": "co/index.js:next",
          "co/index.js:next": true
        }}>{`if (ret.done) return resolve(ret.value);
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Otherwise co attempts to convert `}<MDXTag name="inlineCode" components={components} parentName="p">{`ret.value`}</MDXTag>{` to a promise (we'll skip `}<MDXTag name="inlineCode" components={components} parentName="p">{`toPromise`}</MDXTag>{` for now). `}<MDXTag name="inlineCode" components={components} parentName="p">{`.call`}</MDXTag>{` is there to provide original context in case `}<MDXTag name="inlineCode" components={components} parentName="p">{`ret.value`}</MDXTag>{` is another `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/tj/co#yieldables"
        }}>{`yieldable`}</MDXTag>{` value:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:next
var value = toPromise.call(ctx, ret.value);
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`On next line co checks if value is not falsy and if it is a promise, and if so, it adds appropriate handlers for promise fulfilment or rejection and exits from next.`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:next
if (value && isPromise(value)) return value.then(onFulfilled, onRejected);
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Notice how `}<MDXTag name="inlineCode" components={components} parentName="p">{`onFulfilled`}</MDXTag>{`, `}<MDXTag name="inlineCode" components={components} parentName="p">{`onRejected`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`next`}</MDXTag>{` functions have references to `}<MDXTag name="inlineCode" components={components} parentName="p">{`resolve`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`reject`}</MDXTag>{` callbacks from a new Promise, which was created at the beginning. If one of these functions calls `}<MDXTag name="inlineCode" components={components} parentName="p">{`resolve`}</MDXTag>{` or `}<MDXTag name="inlineCode" components={components} parentName="p">{`reject`}</MDXTag>{`, then top most promise will be settled.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In our case `}<MDXTag name="inlineCode" components={components} parentName="p">{`ret.value`}</MDXTag>{` in our case is `}<MDXTag name="inlineCode" components={components} parentName="p">{`false`}</MDXTag>{`, so next calls `}<MDXTag name="inlineCode" components={components} parentName="p">{`onRejected`}</MDXTag>{` handler, passes it a new TypeError and exits.`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:next
return onRejected(new TypeError('You may only yield a function, promise, generator, array, or object, '
        + 'but the following object was passed: "' + String(ret.value) + '"'));
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`onRejected`}</MDXTag>{` is working quite similar to `}<MDXTag name="inlineCode" components={components} parentName="p">{`onFulfilled`}</MDXTag>{` function with the following difference - instead of running a code until next `}<MDXTag name="inlineCode" components={components} parentName="p">{`yield`}</MDXTag>{` statement, it tries to throw an error inside generator function `}<MDXTag name="em" components={components} parentName="p">{`at the place where it stopped before`}</MDXTag>{`:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:onRejected
var ret;
try {
  ret = gen.throw(err);
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`That means it will replace `}<MDXTag name="inlineCode" components={components} parentName="p">{`yield false`}</MDXTag>{` with `}<MDXTag name="inlineCode" components={components} parentName="p">{`throw err`}</MDXTag>{` and example would look like this:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//example.js
try {
  throw err; //err is a TypeError
} catch (ex) {
  console.log(ex.message);
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`That behaviour is completely normal for generators and is described on MDN (`}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Iterators_and_Generators"
        }}>{`https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Iterators_and_Generators`}</MDXTag>{`):`}</MDXTag>
      <MDXTag name="blockquote" components={components}>
        <MDXTag name="p" components={components} parentName="blockquote">{`You can force a generator to throw an exception by calling its throw() method and passing the exception value it should throw. This exception will be thrown from the current suspended context of the generator, as if the yield that is currently suspended were instead a throw value statement.`}</MDXTag>
      </MDXTag>
      <MDXTag name="p" components={components}>{`At that moment execution flow will get back to example code and, because an error was handled with try-catch, it will be logged in console. Example will continue execution until it will either reach the end of generator or face a new `}<MDXTag name="inlineCode" components={components} parentName="p">{`yield`}</MDXTag>{`:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//example.js
var a = read('index.js', 'utf8');
var b = request('http://google.ca');
var res = yield [a, b];
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`So result of calling `}<MDXTag name="inlineCode" components={components} parentName="p">{`gen.throw`}</MDXTag>{` in `}<MDXTag name="inlineCode" components={components} parentName="p">{`onRejected`}</MDXTag>{` returns an array with a and b:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:onRejected
var ret;
try {
  ret = {value: [a, b], done: false};
}
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`Catch brunch is skipped but it would otherwise reject the promise. Finally, another `}<MDXTag name="inlineCode" components={components} parentName="p">{`next`}</MDXTag>{` call happens: it would skip immediate resolve, convert array to a promise created with Promise.all (check out `}<MDXTag name="inlineCode" components={components} parentName="p">{`arrayToPromise`}</MDXTag>{` function), which will be settled only when `}<MDXTag name="inlineCode" components={components} parentName="p">{`a`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`b`}</MDXTag>{` promises are settled or one of them is rejected. Note how `}<MDXTag name="inlineCode" components={components} parentName="p">{`arrayToPromise`}</MDXTag>{` is making sure that Promise.all argument is an array of promises:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:arrayToPromise
obj.map(toPromise, this)
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}><MDXTag name="inlineCode" components={components} parentName="p">{`toPromise`}</MDXTag>{` will first check if value is not falsy and if it is, it returns a value:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:toPromise
if (!obj) return obj;
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If value is a promise already, toPromise will return it:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:toPromise
if (isPromise(obj)) return obj;
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If value is a generator or a generator function, toPromise will recursively call co, pass it a context and an obj and as a result we will get a new promise:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:toPromise
if (isGeneratorFunction(obj) || isGenerator(obj)) return co.call(this, obj);
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If `}<MDXTag name="inlineCode" components={components} parentName="p">{`obj`}</MDXTag>{` is a function, toPromise will wrap it in a promise. First argument in a function is assumed to be an error object (node.js error handling pattern, so all node js callback APIs are covered):`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:toPromise
if ('function' == typeof obj) return thunkToPromise.call(this, obj);
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`If `}<MDXTag name="inlineCode" components={components} parentName="p">{`obj`}</MDXTag>{` is an array or an object with `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://github.com/tj/co#yieldables"
        }}>{`yieldable`}</MDXTag>{` properties, co will recursively convert them to promises:`}</MDXTag>
      <MDXTag name="pre" components={components}><MDXTag name="code" components={components} parentName="pre" props={{
          "className": "language-js"
        }}>{`//co/index.js:toPromise
if (Array.isArray(obj)) return arrayToPromise.call(this, obj);
if (isObject(obj)) return objectToPromise.call(this, obj);
`}</MDXTag></MDXTag>
      <MDXTag name="p" components={components}>{`So co ensures that `}<MDXTag name="inlineCode" components={components} parentName="p">{`a`}</MDXTag>{` and `}<MDXTag name="inlineCode" components={components} parentName="p">{`b`}</MDXTag>{` are converted to promises, and when both promises are fulfilled - Promise.all will ensure that results are assigned to appropriate indexes in an array. If one of them is rejected, then `}<MDXTag name="inlineCode" components={components} parentName="p">{`.catch`}</MDXTag>{` callback from the example will be able to handle an error (log it somewhere, or re-try once again). In either scenario a new Promise (one returned from co at the very beginning) will be settled, code will be executed asynchronously, and in the manner expected by developer, and will have all benefits of central place for error handling.`}</MDXTag>
      <MDXTag name="p" components={components}>{`In a nutshell this is exactly what happens in co: generators and promises take care of asynchronous operations and error handling, while keeping your code clean and easy to follow. Similar logic will be hidden behind a native support of async programming with `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.youtube.com/watch?v=DqMFX91ToLw"
        }}>{`ES7 async-await`}</MDXTag>{`. If you want to use co, generators and Promises in non ES6 environments, try out 6to5 transpiler (links below).`}</MDXTag>
      <MDXTag name="p" components={components}>{`Thank you and please feel free to ask questions in the comments, follow us on `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.facebook.com/webuniverseio"
        }}>{`facebook`}</MDXTag>{` and `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://twitter.com/webuniverseio"
        }}>{`twitter`}</MDXTag>{` pages, or subscribe to my `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "/rss.xml"
        }}>{`feed`}</MDXTag>{`.`}</MDXTag>
      <MDXTag name="h2" components={components}>{`Links`}</MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`co - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://github.com/tj/co"
          }}>{`https://github.com/tj/co`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`shallow code - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://exponential.io/blog/unnest-callbacks/"
          }}>{`http://exponential.io/blog/unnest-callbacks/`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`6to5 transpiler (babeljs) - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://babeljs.io/docs/learn-es6/"
          }}>{`https://babeljs.io/docs/learn-es6/`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`Promises`}<a name="promises"></a></MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`description - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Promise"
          }}>{`https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Promise`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`support - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://kangax.github.io/compat-table/es6/#Promise"
          }}>{`http://kangax.github.io/compat-table/es6/#Promise`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`examples - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://www.2ality.com/2014/09/es6-promises-foundations.html"
          }}>{`http://www.2ality.com/2014/09/es6-promises-foundations.html`}</MDXTag></MDXTag>
      </MDXTag>
      <MDXTag name="h3" components={components}>{`Generators`}<a name="generators"></a></MDXTag>
      <MDXTag name="ul" components={components}>
        <MDXTag name="li" components={components} parentName="ul">{`description and examples - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*"
          }}>{`https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Statements/function*`}</MDXTag></MDXTag>
        <MDXTag name="li" components={components} parentName="ul">{`support - `}<MDXTag name="a" components={components} parentName="li" props={{
            "href": "http://kangax.github.io/compat-table/es6/#generators"
          }}>{`http://kangax.github.io/compat-table/es6/#generators`}</MDXTag></MDXTag>
      </MDXTag>
      <style dangerouslySetInnerHTML={{
        __html: `
  main h1[itemprop="name"] {font-size: 1.72rem;}
`
      }} />
           </MDXTag>;
  }

}
export const _frontmatter = {
  "title": "Asynchronous programming with ES6 generators, promises and npm-co",
  "date": "2015-02-03T18:20:00.000Z",
  "overview": "Simplify your code by writing<br> an asynchronous code in a synchronous manner",
  "image": "./image.jpg",
  "imageSmall": "./imageSmall.jpg",
  "type": "post"
};
    